@import '../../Styles/variables.scss';

.header-container {
    display: flex;
    justify-content: center;
    background-color: $nav-color; // $nav-dark; 
    height: auto;
    width: 100%;
    // border: 1px solid red;
    height: 90px;

    @media only screen and (max-width: 600px) {
        padding: 20px 0;
        height: 100%;
    }

    .header-bar {
        width: 70%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media only screen and (max-width: 600px) {
            width: 100%;
            flex-direction: column;
        }
    }

    .header-logo-container {
        display: flex;
        align-items: center;
        // border: 1px solid purple;
        box-sizing: border-box;
        // width: 20px;
        height: 100%;

        >img {
            width: 190px;

            @media only screen and (max-width: 600px) {
                width: 100px;
                margin-left: 2rem;
            }
        }

        @media only screen and (max-width: 600px) {
            width: 100%;
            justify-content: space-between;
        }

    }

    .header-content-container {
        width: 100%;
        // height: 100%;
        // border: 1px solid red;
        box-sizing: border-box;
        display: flex;
        justify-content: end;

        @media only screen and (max-width: 600px) {
            height: 100%;
            box-sizing: border-box;
            flex-direction: column;
        }

        .header-content {
            color: #f4f4f4;
            margin: 0 0.5rem 0 0.5rem;
            padding: 0 1rem 0 1rem;
            display: flex;
            align-items: center;
            box-sizing: border-box;

            @media only screen and (max-width: 600px) {

                flex-direction: column;
                box-sizing: border-box;
                height: 40px;
                width: 100%;
                display: none;

                &-active {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    padding: 0.5rem 0 0.5rem 0;
                    background-color: $nav-color;
                }

            }
        }


    }

    @media only screen and (max-width: 600px) {
        .hamburger {
            width: 40px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-right: 2rem;
            align-items: center;
        }

        .hamburger-bar {
            display: flex;
            border-radius: 5px;
            background-color: $primary-color;
            width: 30px;
            height: 4px;
            margin: 2px auto;
            -webkit-transition: all 0.3s ease-in-out;
            transition: all 0.3s ease-in-out;
        }

        .hamburger-bar-active {
            display: flex;
            border-radius: 5px;
            background-color: $primary-color;
            width: 30px;
            height: 4px;
            margin: 2px auto;
            -webkit-transition: all 0.3s ease-in-out;
            transition: all 0.3s ease-in-out;
        }

        .hamburger-bar-active:nth-child(2) {
            opacity: 0;
        }

        .hamburger-bar-active:nth-child(1) {
            transform: translateY(8px) rotate(45deg);
        }

        .hamburger-bar-active:nth-child(3) {
            transform: translateY(-8px) rotate(-45deg);
        }

    }
}

.active {
    display: none;
}

.header-container-mobile {
    @media only screen and (max-width: 600px) {
        background-color: gray;
        height: auto;
        width: 100%;

        .content-container-mobile {
            border: 1px solid gray;
            height: 40px;
            width: 100%;
        }
    }
}