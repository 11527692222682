@import url('https://fonts.googleapis.com/css2?family=Anek+Malayalam:wght@100;200;300;400;500;600;700;800&display=swap');



.quiz-container {
  max-width: 500px;
  min-width: 250px;
  border-radius: 50px;
  background: #ffffff;
  border-radius: 50px;
  margin-top: 100px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  padding: 30px 60px;
}

.quiz-container .active-question-no {
  font-size: 32px;
  font-weight: 500;
  color: #8abff2;
}

.quiz-container .total-question {
  font-size: 16px;
  font-weight: 500;
  color: #e0dee3;
}

.quiz-container h2 {
  font-size: 20px;
  font-weight: 500;
  margin: 0;
}

.quiz-container ul {
  margin-top: 20px;
  margin-left: -40px;
}

.quiz-container ul li {
  text-decoration: none;
  list-style: none;
  color: #2d264b;
  font-size: 16px;
  background: #ffffff;
  border: 1px solid #eaeaea;
  border-radius: 16px;
  padding: 11px;
  margin-top: 15px;
  cursor: pointer;
}

.quiz-container ul .selected-answer {
  background: #8abff2;
  border: 1px solid #8abff2;
}

.quiz-container button {
  background: linear-gradient(90.04deg, #8abff2 0.03%, #76a3bd 99.96%);
  border-radius: 9px;
  font-size: 18px;
  color: #ffffff;
  padding: 10px 42px;
  outline: none;
  border: none;
  cursor: pointer;
  margin-top: 15px;
}

.quiz-container button:disabled {
  background: #e7e8e9;
  color: #9fa3a9;
  cursor: not-allowed;
}

.flex-right {
  display: flex;
  justify-content: flex-end;
}

.result h3 {
  font-size: 24px;
  letter-spacing: 1.4px;
  text-align: center;
}

.result p {
  font-size: 16px;
  font-weight: 500;
}

.result p span {
  color: #8abff2;
  font-size: 22px;
}

@media screen and (max-width: 576px) {
  .quiz-container {
    padding: 20px; /* Reduce padding for smaller screens */
  }

  .quiz-container ul {
    // margin-left: -25px; /* Adjust margin for smaller screens */
  }

  .quiz-container button {
    padding: 10px 30px; /* Reduce padding for smaller screens */
  }

  .result h3 {
    font-size: 20px; /* Reduce font size for smaller screens */
  }

  .result p {
    font-size: 14px; /* Reduce font size for smaller screens */
  }

  .result p span {
    font-size: 18px; /* Reduce font size for smaller screens */
  }
}
