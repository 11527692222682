@import '../../Styles/variables.scss';

.footer-container {
    position: absolute;
    // bottom: 0;
    display: flex;
    justify-content: center;
    background-color: $footer-color; // $nav-dark;
    height: 120px;
    width: 100%;
    color: white;

    @media only screen and (max-width: 600px) {
        height: 80px;
    }


    .footer-bar {
        width: 80%;
        // border: 1px solid red;
        display: flex;
        align-items: center;
        justify-content: space-between
    }

    .footer-logo-container {
        display: flex;
        align-items: center;
        // border: 1px solid green;
        box-sizing: border-box;
        // width: 20px;
        height: 100%;

        >img {
            width: 200px;
        }
    }

    .footer-content-container {
        // border: 1px solid green;
        width: 100%;
        display: flex;
        justify-content: center;

        .footer-content {
            // border: 1px solid red;
            color: #f4f4f4;

            @media only screen and (max-width: 600px) {
                font-size: 10px;
            }
        }
    }

}